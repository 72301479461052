<template>
  <div>
    <div class="pc">
      <div class="options" v-if="option">
        <div class="h8 sub">옵션선택</div>
        <div v-for="(opt,idx) in option" :Key="`opt-${idx}`"
          class="item-option unselect" :class="{'selected': opt.selected}"
          @click="selectOpt(opt)">
          <div class="flex-between" style="align-items: flex-start">
            <div class="body3-medium sub flex-1">{{ opt.name }}</div>
            <div class="body2-bold main">
              {{ opt.price|currencyNum }} <span class="body4 sub">원</span>
            </div>
          </div>
          <div v-if="opt.selected">
            <div v-if="opt.desc !== ''"
              class="body4 sub3" style="padding:4px 0 6px 0">{{ opt.desc }}</div>
            <div v-for="(checkItem, checkIdx) in opt.check_items" :key="`check-${checkIdx}`"
              class="flex-align" style="gap:8px;margin-top:6px">
              <img src="/static/icon/u_check.svg" class="svg-primary">
              <div class="body4 sub">{{ checkItem }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex margin-top-20" style="gap:8px">
        <button class="button is-gray body2-bold" @click="$refs.inquiryModal.open()"
                style="width: 110px; height: 52px">문의</button>
        <button class="button is-primary body2-bold"
                style="width: 100%; height: 52px" @click="clickOrder">구매하기</button>
      </div>
    </div>
    <div class="mobile">
      <transition name="fade">
        <div class="dim" v-if="option && expand" @click="expand=false"></div>
      </transition>
      <div class="fixed-bottom">
      <transition name="slide">
        <div class="options" v-if="option && expand">
        <div class="flex-between">
          <div class="subtitl5 sub">옵션선택</div>
          <i class="material-icons btn-close" @click="expand=false">close</i>
        </div>
        <div v-for="(opt,idx) in option" :Key="`opt-${idx}`"
             class="item-option unselect" :class="{'selected': opt.selected}"
             @click="selectOpt(opt)">
          <div class="flex-between" style="align-items: flex-start">
            <div class="body3-medium sub flex-1">{{ opt.name }}</div>
            <div class="body2-bold main">
              {{ opt.price|currencyNum }} <span class="body4 sub">원</span>
            </div>
          </div>
          <div v-if="opt.selected">
            <div v-if="opt.desc !== ''"
                 class="body4 sub3" style="padding:4px 0 6px 0">{{ opt.desc }}</div>
            <div v-for="(checkItem, checkIdx) in opt.check_items" :key="`check-${checkIdx}`"
                 class="flex-align" style="gap:8px;margin-top:6px">
              <img src="/static/icon/u_check.svg" class="svg-primary">
              <div class="body4 sub">{{ checkItem }}</div>
            </div>
          </div>
        </div>
      </div>
      </transition>
      <div class="fixed-bottom-button">
        <div class="flex" style="gap:8px">
          <button class="button is-gray body2-bold" @click="$refs.inquiryModal.open()"
                  style="width: 110px; height: 48px">문의</button>
          <button class="button is-primary body2-bold" @click="clickOrder"
                  style="width: 100%; height: 48px">구매하기</button>
        </div>
      </div>
    </div>
    </div>
    <product-inquiry-modal ref="inquiryModal"></product-inquiry-modal>
  </div>
</template>

<script>
  import ProductInquiryModal from "../modal/ProductInquiryModal";
  export default {
    name: "TemplateButton",
    components: { ProductInquiryModal },
    props: {
      product: {
        type: Object
      }
    },
    created() {

    },
    data() {
      return {
        expand: false
      }
    },
    methods: {
      clickOrder() {
        if(!this.isLogin) {
          this.$store.commit('setPrevPath', this.$route.fullPath);
          this.toast('로그인 후 진행해주세요.');
          this.$router.push('/signin');

          return;
        }

        let option = this.option ? this.option.filter(i => i.selected) : [];

        if(this.isMobile && !this.expand && this.option && this.option.length>0) {
          this.expand = true;
          return;
        }

        if(this.option && option.length === 0) {
          this.toast('옵션을 선택해주세요');
          return;
        }
        let product_price = 0;
        let discount_price = 0;
        if(option.length === 0) {
          product_price = this.product.price.sale_price;
          discount_price = this.product.price.price - this.product.price.sale_price;
        } else {
          let opt = option[0];
          if(opt.is_discount && opt.discount_rate) {
            discount_price = opt.price * (opt.discount_rate / 100);
            product_price = opt.price - discount_price;
          } else {
            product_price = opt.price;
          }
        }

        let order_product = {
          product_id: this.product.id,
          quantity: 1,
          discount_price: discount_price,
          product_price: product_price,
          total_price: product_price,
          product: this.cloneItem(this.product),
          order_product_option: option,
          required_option_price: 0,
          add_option_price: 0
        }
        this.$store.commit('setBasket', order_product);
        this.routeGa('', this.product.title, '결제하기');
        this.routerPush('/template_account');
      },
      selectOpt(opt) {
        this.option.forEach(i => {
          i.selected = false;
        });
        opt.selected = true;
      }
    },
    computed: {
      isMobile() {
        return window.innerWidth < 1024;
      },
      option() {
        return this.product.selectors && this.product.selectors.length>0 ?
          this.product.selectors[0].options.map(i=> { i.selected=false; return i; }) : undefined;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .options
    border-top 1px solid $gray1
    padding-top 20px
    margin-top 20px
    display grid
    grid-gap 12px

  .item-option
    border 1px solid $gray1
    border-radius 8px
    padding 16px 24px
  .selected
    border 1px solid $primary

  .dim
    background-color rgba(0,0,0,0.5)
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    z-index 10

  .fixed-bottom
    position fixed
    bottom 0
    left 0
    width 100%
    z-index 11
    .options
      background-color white
      border-top-left-radius 12px
      border-top-right-radius 12px
      padding 20px 16px
    .fixed-bottom-button
      border-top 1px solid $gray1
      padding 12px
      background-color white
      position relative
      z-index 1
    .btn-close
      padding 0 2px
      font-size 22px
      color $sub4

  .slide-enter-active
    transition all .3s ease

  .slide-leave-active
    transition all .3s ease

  .slide-enter, .slide-leave-to
    transform translateY(300px)
</style>
